



















import { Component, Vue, Prop } from 'vue-property-decorator';
import Masthead from './Common/Masthead.vue';

@Component({
  components: {
    Masthead,
  },
})
export default class SiteMasthead extends Vue {
  @Prop()
  public readonly mini!: boolean;

  @Prop()
  public readonly title!: string;

  @Prop()
  public readonly subtitle!: string;

  @Prop({ default: 'var(--brand-primary)' })
  public readonly siteMastheadColour!: string;

  @Prop()
  public readonly image!: string;

  @Prop({ default: true })
  public readonly darken!: boolean;

  @Prop({ default: false })
  public readonly extraDark!: boolean;

  @Prop()
  public readonly height!: string;
}
