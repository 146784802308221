





































import { Component, Vue } from 'vue-property-decorator';
import { API, Config } from 'client-website-ts-library/services';
import { Office } from 'client-website-ts-library/types';
import Navigation from './Navigation.vue';
import NavigationSide from './NavigationSide.vue';

@Component({
  components: {
    Navigation,
    NavigationSide,
  },
})
export default class Header extends Vue {
    public isStuck = false;

    public mobileNavActive = false;

    /* Configuration Options */
    public gradient = false;

    public fixed = false;

    public stickyNav = true;

    public eventListenerOpts: AddEventListenerOptions | EventListenerOptions = { passive: true };

    public offices: Office[] = [];

    mounted() {
      this.checkScroll();

      this.$router.afterEach(() => {
        this.mobileNavActive = false;
      });

      API.Franchises.GetOffices(Config.Website.Settings!.WebsiteId).then((offices) => {
        this.offices = offices;
      });
    }

    checkScroll(): void {
      this.isStuck = window.scrollY > 0;
    }

    created() {
      window.addEventListener('scroll', this.checkScroll, this.eventListenerOpts);
    }

    beforeDestroy() {
      window.removeEventListener('scroll', this.checkScroll, this.eventListenerOpts);
    }
}
